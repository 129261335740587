import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 100px 130px; 
`

export const Title = styled.p`
  font-size: 35px;
  margin-bottom: 50px;
  font-family: "Playfair Display", serif;
`

export const Li = styled.li`
  font-size: 18px;
  font-family: "Work Sans", serif;
  line-height: 30px;
  margin: 12px 0;
`

export const Text = styled.p`
  font-size: 18px;
  font-family: "Work Sans", serif;
  line-height: 30px;
`