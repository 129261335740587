import styled from "styled-components";

export const PageTitleWrapper = styled.div`
  background-color: #EAE1D6;
  width: 100%;
  height: 400px;
  color: #000;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 992px) {
    height: 250px;
  }
`;

export const PageTitle = styled.h1`
  font-size: 45px;
  
  @media (max-width: 992px) {
    font-size: 20px;
  }
`
