import React from 'react';
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import GlobalStyles from "../GlobalStyles"
import {PageTitleWrapper, PageTitle} from "../components/PageTitle/PageTitle"
import { Li, Text, Title, Wrapper } from "../components/PolicyPrivacy"
import Helmet from "react-helmet"

const PrivacyPolicy = () => (
  <>
    <GlobalStyles />
    <Helmet>
      <meta name="viewport"
            content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta charSet="utf-8" />
      <title>Kancelaria Merda</title>
      <link rel="canonical" href="https://kancelaria-merda.pl/" />
    </Helmet>
    <Header />
    <PageTitleWrapper>
      <PageTitle>Polityka prywatności</PageTitle>
    </PageTitleWrapper>
    <Wrapper>
      <Text>
        Zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 24 kwietnia 2016r.
        w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
        swobodnego przepływ takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
        ochronie danych) zwanym dalej RODO, zwracamy się do Pani/Pana z uprzejmą prośbą o zapoznanie
        się z informacjami podanymi poniżej, w związku z ochroną danych osobowych oraz podstawą
        przetwarzania danych.
      </Text>
      <ol>
        <Li>
            Administratorem Pani/Pana danych osobowych jest Kancelaria Radcy Prawnego Bartosz
            Merda, tel. 508343810, mail kancelaria@radcabm.pl.
        </Li>
        <Li>
          Pani/Pana dane będą przetwarzane w celach i na podstawie:
          <ul>
            <Li>w celu zawarcia i wykonania umowy – usługi prawnej, udzielonego pełnomocnictwa.</Li>
          </ul>
        </Li>
        <Li>Przetwarzane będą zwykłe kategorie danych osobowych, w szczególności takie jak nr
          telefonu oraz adres mail.</Li>
        <Li>W zakresie zleconej sprawy – zawartej umowy z pełnomocnikiem, mogą również być
          przetwarzane dane osobowe dotyczące Pani/Pana stanu zdrowia, sytuacji życiowej, stanu
          rodzinnego, co jest konieczne do wykonania usługi.</Li>
        <Li>Pani/ Pana dane będą przetwarzane przez upoważnionych pracowników i współpracowników
          Administratora. Dane będą również przekazywane podmiotom przetwarzającym / innym odbiorcom
          wyłącznie w celu zawarcia i wykonania umowy lub wypełnienia obowiązku prawnego.</Li>
        <Li>Pani/ Pana dane nie będą przekazywane do państw trzecich (spoza UE i EOG).</Li>
        <Li>
          Pani / Pana dane osobowe będą przetwarzane przez okres:
          <ul>
            <Li>do końca roku kalendarzowego następującego po roku, w którym ostatni raz Pani /Pan się z nami
              kontaktował,</Li>
            <Li>do końca okresu przedawnienia potencjalnych roszczeń z umowy w razie zawarcia usługi prawnej,
              udzielonego pełnomocnictwa.</Li>
          </ul>
        </Li>
        <Li>Posiada Pani/Pan prawo dostępu do treści swoich danych osobowych, prawo ich
          sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, wniesienia
          sprzeciwu.</Li>
        <Li>Dla danych przetwarzanych na podstawie zgody, ma Pani/Pan prawo do cofnięcia zgody w
          dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na
          podstawie zgody przed jej cofnięciem.</Li>
        <Li>Jeżeli uzna Pani/Pan, że przetwarzanie danych osobowych Pani/Pana dotyczących, narusza
          przepisy, ma Pani/Pan prawo wniesienia skargi do organu nadzorczego, tj. Prezesa Urzędu Ochrony
          Danych Osobowych.</Li>
        <Li>Podanie przez Panią/Pana danych osobowych w postaci adresu email jest dobrowolne
          jednakże niezbędne w celu zawarcia i realizacji umowy. Podanie nr telefonu nie jest konieczne,
          aczkolwiek wskazane w celu ułatwienia kontaktu.</Li>
      </ol>
    </Wrapper>
    <Footer />
  </>
)

export default PrivacyPolicy;